import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    Tabs,
    Button,
    Form,
    Select,
    Input,
    Upload,
    DatePicker
} from 'antd';
import { createBusinessAccount, updateBussinessAccount } from '../store/businessAccountSlice';
import { toast } from 'react-hot-toast';
import { isEmail, isPhoneNumber } from '../../../utils/functions';
import { CameraOutlined, UploadOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import './style.css'
import moment from 'moment';



export default function BusinessAccountForm({ businessAccount, setIsDrawerOpen }) {
    const dispatch = useDispatch();
    const { query } = useSelector(state => state.businessAccount.businessAccount);
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [contractUrl, setContractUrl] = React.useState('');
    const [expiryDate, setExpiryDate] = React.useState('');
    const [accoutLogoUrl, setAccountLogoUrl] = React.useState('');
    useEffect(() => {
        if (businessAccount) {
            form.setFieldsValue(businessAccount);
            setContractUrl(businessAccount?.contractUrl || '');
            setExpiryDate(businessAccount?.contractExpiryDate ? moment(businessAccount?.contractExpiryDate) : '');
            setAccountLogoUrl(businessAccount?.accoutLogoUrl || '');
        }
    }, [dispatch, businessAccount, form]);
    const saveBusinessAccount = (values) => {
        setIsLoading(true);
        if (!isEmail(values.email)) {
            toast.error('Email is not valid');
            setIsLoading(false);
            return;
        }
        if (businessAccount) {
            const { password, ...rest } = values;

            dispatch(updateBussinessAccount({ data: { ...rest,accoutLogoUrl, _id: businessAccount.id, contractExpiryDate: new Date(expiryDate) }, query }))
                .then((data) => {
                    if (!data.payload?._id) {
                        toast.error('updating business account failed');
                        return;
                    }
                    toast.success('Business Account Updated')
                    setIsDrawerOpen(false);
                })
            return
        }
        dispatch(createBusinessAccount({ ...values,accoutLogoUrl, contractUrl: contractUrl, contractExpiryDate: new Date(expiryDate) }))
            .then((data) => {
                if (!data.payload?.businessAccount || data?.payload?.error || data?.error) {
                    toast.error('creating business account failed');
                    return;

                }
                toast.success('Business Account Created');
                form.resetFields();
                setIsDrawerOpen(false);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    const onFinishFailed = (e) => {
        toast.error('Submit failed!');
    };

    const removeContractUrl = () => {
        setContractUrl('');
    }


    const getName = (url) => {
        const regex = /[^/]+(?=\.[^.]+$)/;
        const result = url.match(regex);
        return result[0]?.length > 10 ? (result[0]?.substring(0, 10) + '...') : result[0];
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const customRequest = (name) => async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('admin/uploadDocument', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            onSuccess();
            setContractUrl(response.data.fileUrl)
            toast.success('File uploaded successfully');
        } catch (error) {
            toast.error('File upload failed');
            console.log("upload error ==>", error);
            // onError(error);
        }
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    };


    const uploadAccoutLogo = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post('/admin/uploadDocument', formData);
            onSuccess(response.data);
            setAccountLogoUrl(response.data.fileUrl);
        } catch (error) {
            onError(error);
        }
    };

    const BusinessAccountDetails = () =>
        <Form
            form={form}

            labelAlign='left'
            className='w-full flex flex-col gap-y-0   '
            name="Info"
            colon={false}
            labelCol={{
                span: 12,
            }}

            wrapperCol={{
                span: 24,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinishFailed={onFinishFailed}
            onFinish={saveBusinessAccount}
        >
            <Form.Item
                label="_id"
                name='id'
                className='p-0 m-2'
                hidden
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Name"
                name='name'
                className='p-0 m-2'

            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Email"
                name='email'
                className='p-0 m-2'
                rules={[{
                    validator: (_, value) => {
                        if (isEmail(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('must be a valied email'));
                    },
                },
                ]}

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Mobile Number"
                name='mobileNumber'
                className='p-0 m-2'
                rules={[{
                    validator: (_, value) => {
                        if (Number.isInteger(+value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Value must be a number'));
                    },
                },
                ]}

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Business Registration Number"
                name='commercialRegistrationNumber'
                className='p-0 m-2'
                rules={[{
                    validator: (_, value) => {
                        if (Number.isInteger(+value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Value must be a number'));
                    },
                },
                ]}

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Password"
                name='password'
                className='p-0 m-2'
                hidden={businessAccount}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Contact Person Name"
                name='primaryContactName'
                className='p-0 m-2'

            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Contact Person Phone Number"
                name='receptionNumber'
                className='p-0 m-2'
                rules={[{
                    validator: (_, value) => {
                        if (Number.isInteger(+value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Value must be a number'));
                    },
                },
                ]}


            >
                <Input />
            </Form.Item>



            <Form.Item
                label="Status"
                name='status'
                className='p-0 m-2 pb-4 border-b'
            >
                <Select
                    options={[
                        { value: 'notCompleted', label: 'Not Comleted' },
                        { value: 'active', label: 'Active' },
                        { value: 'inactive', label: 'Inactive' },
                    ]}
                />
            </Form.Item>

            <p className='pt-8 contract '>Contract</p>

            {
                contractUrl ?
                    (
                        <Form.Item initialValue={contractUrl} className='mt-8 flex flex-col justify-center items-center w-full'>
                            <div className='pt-0 mt-0 doc-link-cont-single' >
                                <a className='pt-0 flex flex-col justify-center items-center' href={contractUrl} target="_blank" rel="noopener noreferrer" >
                                    <CheckOutlined className='p-0 mr-2' />
                                    <p>File Uploaded</p>
                                </a>
                            </div>
                            <div className='flex justify-around'>
                                {getName(contractUrl)}
                                <DeleteOutlined onClick={() => removeContractUrl()} />
                            </div>
                        </Form.Item>

                    ) : (
                        <Form.Item name={'contractUrl'} initialValue={contractUrl} className=' flex flex-col justify-center items-center mt-8' >
                            <Upload className='flex flex-col w-full  ' customRequest={customRequest()}  >
                                <Button className="ant-upload-doc flex flex-col w-full " style={{ width: '400px' }} icon={<UploadOutlined />}>Upload Contract</Button>
                            </Upload>
                        </Form.Item>
                    )
            }
            <div>
                <Form.Item className=' p-0 m-2 date-picker-item' label="Expiry Date" initialValue='' value={expiryDate ? expiryDate : ''}>
                    <DatePicker
                        disabledDate={disabledDate}
                        value={expiryDate ? expiryDate : null}
                        //  value={documents['qatarWorkResidentFront']?.expiryDate ? moment(documents['qatarWorkResidentFront']?.expiryDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)') : ''}
                        onChange={(date, dateString) => {
                            setExpiryDate(date ? date : null)
                        }
                        }
                        style={{ width: '100%' }}
                    />
                </Form.Item>


            </div>



            <Form.Item className='flex justify-end'>
                <Button type="primary" htmlType="submit" disabled={isLoading}>
                    {businessAccount ? 'Save' : 'Create Account'}
                </Button>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>
        </Form>



    return (
        <div className='flex flex-col'>
            <p className='form-subHeader'>Business Details</p>
            <div className=' flex w-full flex-col px-8 pt-8'>

                <div className=' flex flex-col'>
                    <div className='flex  gap-x-4'>

                        <Form.Item label="" valuePropName="fileList" getValueFromEvent={normFile}>
                            <Upload
                                customRequest={uploadAccoutLogo}
                                listType="picture-circle"
                                maxCount={1}
                                fileList={accoutLogoUrl ? [{ url: accoutLogoUrl }] : []}
                                onRemove={() => setAccountLogoUrl('')}
                            >
                                {!accoutLogoUrl && <button
                                    style={{
                                        // border: 0,
                                        background: 'none',
                                    }}
                                    type="button"
                                >
                                    <CameraOutlined />
                                    <div
                                        style={{
                                            marginTop: 8,
                                        }}
                                    >
                                    </div>
                                </button>}
                            </Upload>
                        </Form.Item>
                        <p className='w-3/4 text-center'>Please provide all the necessary details to add a business account. Complete information ensures accurate record-keeping and smooth processing.</p>
                    </div>
                    <BusinessAccountDetails />
                </div>
            </div>

        </div>
    )
}
