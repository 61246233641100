import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Input } from 'antd';
import { setPricesPopup, getPriceList, updatePriceList, getZones, setPriceslist } from '../../store/businessAccountSlice';
import Loader from '../../../../components/loader';
import {defaultTripPrice} from '../../../../utils/constants';
import './style.css';

const PricePop = ({ accountId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const { pricesPopup } = useSelector((state) => state.businessAccount.businessAccount);
  const { pricesList } = useSelector((state) => state.businessAccount.businessAccount);
  const { zonesList } = useSelector((state) => state.businessAccount.businessAccount);
  const { isModalOpen, data } = pricesPopup;

  useEffect(() => {
    setIsLoading(true);
    if (isModalOpen) {
      dispatch(getZones({}));
      dispatch(getPriceList({ businessID: accountId, vehicleTypeId: pricesPopup.data.id }));
    }
    setIsLoading(false);
  }, [data, dispatch, isModalOpen, accountId, pricesPopup.data.id]);

  useEffect(() => {
    
    if (pricesList.length > 0) {
      form.setFieldsValue({ zonePricing: pricesList[0].zonePricing });
    } else {
      if (zonesList) {
        const emptyPriceObject = []
        zonesList.forEach((zoneFrom) => {
          zonesList.forEach((zoneTo) => {
            emptyPriceObject.push({
              from: zoneFrom.id,
              to: zoneTo.id,
              price: defaultTripPrice,
              currency: 'QAR',
              id: `${zoneTo.id}${zoneFrom.id}`,
            });
          });
        });
        form.setFieldsValue({zonePricing: emptyPriceObject});
      }
    }
  }, [pricesList, form, zonesList]);

  const handleOk = (values) => {
    dispatch(updatePriceList({ businessID: accountId, data: { vehicleTypeId: pricesPopup.data.id, zonePricing: values.zonePricing } })).then(
      () => {
        dispatch(setPricesPopup({ data: {}, isModalOpen: false }));
        dispatch(setPriceslist([]));
        form.resetFields();
      }
    );
  };

  const handleCancel = async () => {
    dispatch(setPricesPopup({ data: {}, isModalOpen: false }));
    form.resetFields();
    await dispatch(setPriceslist([]));
  };

  const ZonePriceListTable = () => (
    <>
      {zonesList.map((zoneFrom, index) => (
        <div className='flex prices-table-row' key={zoneFrom.id}>
          {zonesList.map((zoneTo, i) => (
            <div key={zoneTo.id + zoneFrom.id}>
              <Form.Item hidden initialValue={zoneFrom.id} name={['zonePricing', i + zonesList.length * index, 'from']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item hidden initialValue={zoneTo.id} name={['zonePricing', i + zonesList.length * index, 'to']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item
                className='flex price-item'
                initialValue={
                  pricesList.length > 0
                    ? pricesList[0].zonePricing.find((e) => e.from === zoneFrom.id && e.to === zoneTo.id)?.price
                    : '0'
                }
                name={['zonePricing', i + zonesList.length * index, 'price']}
              >
                <Input className='flex text-end price-input' placeholder="input placeholder" addonAfter="QA" />
              </Form.Item>
              <Form.Item hidden initialValue='QAR' name={['zonePricing', i + zonesList.length * index, 'currency']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item hidden name={['zonePricing', i + zonesList.length * index, 'id']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
            </div>
          ))}
        </div>
      ))}
    </>
  );

  const EmptyTable = () => (
    <>
      {zonesList.map((zoneFrom, index) => (
        <div className='flex prices-table-row' key={zoneFrom.id}>
          {zonesList.map((zoneTo, i) => (
            <div key={zoneTo.id + zoneFrom.id}>
              <Form.Item hidden initialValue={zoneFrom.id} name={['zonePricing', i + zonesList.length * index, 'from']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item hidden initialValue={zoneTo.id} name={['zonePricing', i + zonesList.length * index, 'to']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item className='flex price-item' name={['zonePricing', i + zonesList.length * index, 'price']}>
                <Input initialValue={120} className='flex text-end price-input' placeholder="input placeholder" addonAfter="QA" />
              </Form.Item>
              <Form.Item hidden initialValue='QAR' name={['zonePricing', i + zonesList.length * index, 'currency']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item hidden name={['zonePricing', i + zonesList.length * index, 'id']}>
                <Input placeholder="input placeholder" />
              </Form.Item>
            </div>
          ))}
        </div>
      ))}
    </>
  );


  return (
    <>
    

      <Modal
        title={`${data?.make?.en} ${data?.model?.en}`}
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        className='price-modal'
        footer={null}
      >
        { (isLoading || !zonesList) ?
          <Loader />
          :
       <>
        <div className='price-header-container flex'>
          {zonesList.map((zone) => (
            <Form.Item className='flex justify-end' key={zone.id}>
              <p className='zone-title'>{zone.name}</p>
            </Form.Item>
          ))}
        </div>

        <div className='flex'>
          <div className='zone-side'>
            {zonesList.map((zone) => (
              <Form.Item className='flex justify-end' key={zone.id}>
                <p className='zone-title'>{zone.name}</p>
              </Form.Item>
            ))}
          </div>

          <Form name='priceList' form={form} onFinish={handleOk}>
            {pricesList.length > 0 ? <ZonePriceListTable /> : <EmptyTable />}
          </Form>
        </div>

        <Form form={form} onFinish={handleOk}>
          <Form.Item className='flex justify-end'>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
        </>
          }
      </Modal>
    
    </>
  );
};

export default PricePop;