export const LOGIN_ROUTE = "/login";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";

export const MANAGE_USERS_ROUTE = "/manage-users";
export const MANAGE_GEOFENCE = "/manage-geofencing";
export const MANAGE_SETTINGS = "/settings";
export const MANAGE_DRIVER_ROUTE = "/manage-driver";
export const MANAGE_VEHICLE_ROUTE = "/vehicle-management";
export const MANAGE_LIVE_MAP = "/live-map-management";
export const MANAGE_NOTIFICATIONS = "/manage-notifications";
export const ADMIN_USERS = "/admin-users";
export const DRIVERS = "/manage-drivers";
export const BUSINESS_ACCOUNTS = "/manage-business-accounts";
export const ZONES = "/manage-zones";
export const DASHBOARD = "/dashboard";
const MANAGE_VEHICLES = "/manage-vehicles";
const MANAGE_TRIPS = "/manage-trips";

export const ROUTE_NAME_LOOKUP = {
  [MANAGE_USERS_ROUTE]: ["label.user", "label.management"],
  [MANAGE_GEOFENCE]: ["label.geoFence", "label.management"],
  [MANAGE_NOTIFICATIONS]: ["label.manage", "label.notification"],
  [MANAGE_SETTINGS]: ["label.settings"],
  [MANAGE_DRIVER_ROUTE]: ["label.driver", "label.management"],
  [MANAGE_VEHICLE_ROUTE]: ["label.vehicle", "label.management"],
  [MANAGE_LIVE_MAP]: ["Live Map"],
  [ADMIN_USERS]: 'Admin Users Management',
  [DRIVERS]: 'Drivers Management',
  [MANAGE_VEHICLES]: 'Vehicles Management',
  [MANAGE_TRIPS]: 'Manage Trips',
  [BUSINESS_ACCOUNTS]: ' Business account management',
  [ZONES]: 'Zones management',
  [DASHBOARD]: 'Main Dashboard',
};


export const defaultTripPrice= 100;