import React, { useState, useEffect, useMemo } from 'react'
import './style.css'
import { useSelector, useDispatch } from 'react-redux'
import MiniGraph from '../../../assets/img/MiniGraph.svg'
import driversIcon from '../../../assets/img/driversicon.svg'
import tripsIcon from '../../../assets/img/tripsIcon.svg'
import vehiclesIcon from '../../../assets/img/vehiclesIcon.svg'
import BarChart from './components/BarcharGraph';
import { Space, Select, Button, Table } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Filters from '../components/Filters';
import CustomButton from '../../../components/CustomButton/CustomButton'
import { getBusinessAccount, getDashData, setDashBoardQuery, getTripsOfTheDay , exportReport } from '../store/businessAccountSlice';
import { RenderColumnComponent } from '../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent';
import moment from 'moment/moment';
import Loader from '../../../components/loader'
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import Charts from './charts'
import { useParams } from 'react-router-dom'
import StatusComponent from '../../../components/StatusComponent/StatusComponent';
import UrlPopup from './components/UrlPopUp';
import toast from 'react-hot-toast'

export default function BusinessDashboard() {
  const { accountId } = useParams();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [urlPopUp, setUrlPopUp] = useState({isOpen: false, url: ''});
  const businessAccount = useSelector((state) => state.businessAccount?.businessAccount.businessAccounts.filter(business => business.id === accountId)[0]);
  const {dashboardQuery} = useSelector(state => state.businessAccount.businessAccount)
  const dashData = useSelector(state => state.businessAccount.businessAccount.dashboardData)
  const [topThree, setTopThree] = useState('topBusiness')
  const tripsOfTheDay = useSelector(state => state.businessAccount.businessAccount.tripsOfTheDay)
  const [calDate, setCalDate] = useState(calculateDates(new Date()))

  function calculateDates(date) {
    const currentDate = date
    const startDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
    startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC
    const endDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    endDefaultDate.setUTCHours(23, 59, 59, 999); // End of the day in UTC

    return {
      startDate: startDefaultDate.toISOString().split('T')[0],
      endDate: endDefaultDate.toISOString().split('T')[0]
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getBusinessAccount({ accountId }));
      await dispatch(getDashData({ accountId }));
      await dispatch(getTripsOfTheDay({ businessAccountId: accountId, date: new Date().toISOString() }));
    };
    setLoading(true)
    fetchData().then(() => setLoading(false));

  }, [dispatch]);

  function calculateDates(date) {
    const currentDate = date
    const startDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
    startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC
    const endDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    endDefaultDate.setUTCHours(23, 59, 59, 999); // End of the day in UTC

    return {
      startDate: startDefaultDate.toISOString().split('T')[0],
      endDate: endDefaultDate.toISOString().split('T')[0]
    };
  };
  const getMonthName = (monthNumber) => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[monthNumber];
  };
  const increaseMonthByOne = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return newDate;
  };
  const decreaseMonthByOne = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    return newDate;
  };


  const [isFilterOpen, setIsFilterOpen] = React.useState(false)
  const mappingObj = {
    totalRevenue: { name: 'Total Cost', img: MiniGraph, suffix: 'QAR' },
    totalRides: { name: 'Total Rides', img: MiniGraph },
    tripsThisDay: { name: 'Trips This Day', img: MiniGraph, },
    canceledTrips: { name: 'Canceled Trips', img: tripsIcon },
    onGoingRides: { name: 'On Going Trips', img: tripsIcon },
  }

  //   useEffect(() => {
  //     dispatch(getTripCount(dashboardQuery))
  //   }, [dashboardQuery])


  const firstRowData = {
    totalRevenue: dashData?.totalCost,
    totalRides: dashData?.totalRides,
    tripsThisDay: dashData?.totalTripsToday,
  }
  const secondRowData = {
    canceledTrips: dashData?.canceledTrips,
    onGoingRides: dashData?.activeRides,
  }

  const graphData = {
    revenue: 160.83
  }
  const firstRow = Object.keys(firstRowData).map((key, index) => {
    return <div className='minor-container' key={key}>
      <div  >
        <div className='flex'>
          <p className='upper-text'>{`${firstRowData[key]} ${mappingObj[key]?.suffix || ''}`} </p>
        </div>
        <div>
          <p className='under-text'>{mappingObj[key].name}</p>
        </div>
      </div>
    </div>
  })

  const secondRow = Object.keys(secondRowData).map((key, index) => {
    return <div className='minor-container-second' key={key}>
      <div   >
        <div className='number-container'>
          <img src={mappingObj[key].img} alt='mini-graph' />
          <p className='upper-text'>{`${secondRowData[key]} ${mappingObj[key]?.suffix || ''}`} </p>
        </div>
        <p className='under-text'>{mappingObj[key].name}</p>
      </div>
    </div>
  })
  const data = [
    { date: 'Jul', value: 0 },
    { date: 'Aug', value: 0 },
    { date: 'Sep', value: 0 },
    { date: 'Oct', value: 0 },
    { date: 'Nov', value: dashData?.totalRevenue },
    // Add more data points here
  ];
  const config = {
    data,
    xField: 'date',
    yField: 'value',
    xAxis: {
      type: 'timeCat', // for date-based x-axis
      tickCount: 5,
    },
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    yAxis: {
      label: {
        formatter: (v) => `${v}`, // format y-axis labels if needed
      },
    },

    smooth: true, // for a smooth line chart
    height: 300,
  };


  const intervals = [
    { range: '00:00-01:00', count: 0 },
    { range: '01:00-02:00', count: 0 },
    { range: '02:00-03:00', count: 0 },
    { range: '03:00-04:00', count: 0 },
    { range: '04:00-05:00', count: 0 },
    { range: '05:00-06:00', count: 0 },
    { range: '06:00-07:00', count: 0 },
    { range: '07:00-08:00', count: 0 },
    { range: '08:00-09:00', count: 0 },
    { range: '09:00-10:00', count: 0 },
    { range: '10:00-11:00', count: 0 },
    { range: '11:00-12:00', count: 0 },
    { range: '12:00-13:00', count: 0 },
    { range: '13:00-14:00', count: 0 },
    { range: '14:00-15:00', count: 0 },
    { range: '15:00-16:00', count: 0 },
    { range: '16:00-17:00', count: 0 },
    { range: '17:00-18:00', count: 0 },
    { range: '18:00-19:00', count: 0 },
    { range: '19:00-20:00', count: 0 },
    { range: '20:00-21:00', count: 0 },
    { range: '21:00-22:00', count: 0 },
    { range: '22:00-23:00', count: 0 },
    { range: '23:00-24:00', count: 0 },
  ];

  dashData?.tripsToday.forEach(trip => {
    const startHour = new Date(trip.startAt).getUTCHours();
    intervals[startHour].count += 1;
  });

  const intervalData = intervals.map(interval => interval.count);

  const columns = [
    {
      title: 'Rider Name',
      dataIndex: 'riderName',
      key: 'RiderName',
      sorter: false,
      width: 180,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.rider?.name}`)}</div>,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      sorter: false,
      width: 180,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.vehicle?.vehicleType?.make?.en || 'N/A'}/${trip?.vehicle?.vehicleType?.model?.en || 'N/a'}`)}</div>,
    },
    {
      title: 'Pick Up Time',
      dataIndex: 'startAt',
      key: 'startAt',
      width: 180,
      render: (text, trip) => RenderColumnComponent(true)(moment(trip.startAt).format('YYYY-MM-DD HH:mm')),
    },
    {
      title: 'Pick Up',
      dataIndex: 'pickUp',
      key: 'pickUp',
      sorter: false,
      width: 150,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.zoneFrom?.coordinates?.name?.en || 'N/a'}`)}</div>,
    },
    {
      title: 'Drop Off',
      dataIndex: 'dropOff',
      key: 'dropOff',
      sorter: false,
      width: 150,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.zoneTo?.coordinates?.name?.en || 'N/a'}`)}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,

      key: 'status',
      sorter: false,
      render: (text, vehicle) => RenderColumnComponent(true)(<StatusComponent status={vehicle.status} />),
    },
    {
      title: 'Price',
      dataIndex: 'status',
      width: 100,

      key: 'status',
      sorter: false,
      render: (text, trip) => RenderColumnComponent(true)(trip.price || '----'),
    },

  ];

  return (

    <>
      {!dashData ? <Loader /> :
        <>
          <div className='main-container'>
            <>
              <div className='name-container'>
                <p className='subpage-header'>{businessAccount?.name}<span>{'> '}Business Dashboard</span></p>
              </div>
              {firstRow}
              {secondRow}
            </>
            <div className='w-full'>
              <Charts />
            </div>
            <div className='container-b'>
            </div>
            <div className="calender-container">
              <div className='flex w-full flex-col justify-between '>
                <div className='font-bold text-xl flex w-[100%]  justify-between cal-header-title items-center gap-x-2 '>
                  <p>
                    Trips Of the Day
                  </p>
                  <div className='flex'>
                <CustomButton
                  btnType="extra"
                  icon="pdf"
                  btnText="Export As PDF"
                  className='!h-9 ml-4 max-w-24 self-end'
                  handleSubmit={e => {
                    setLoading(true)
                    dispatch(exportReport({startAt : dashboardQuery.startDate, endAt:dashboardQuery.endDate,type: 'pdf' , businessAccountId : accountId})).then(res => {
                      if(!res.payload || res.error){
                        toast.error('Failed to export as PDF') 
                      }else{
                        setUrlPopUp({url : res.payload , isOpen : true})  
                      }
                    }).then(() => setLoading(false))
                  }}
                  />
                <CustomButton
                  btnType="extra"
                  icon="csv"
                  btnText="Export AS CSV"
                  className='!h-9 ml-4 max-w-24 self-end'
                  handleSubmit={e => {
                    setLoading(true)
                    dispatch(exportReport({startAt : dashboardQuery.startDate, endAt:dashboardQuery.endDate,type: 'csv' , businessAccountId : accountId})).then(res => {
                      if(!res.payload || res.error){
                        toast.error('Failed to export as CSV') 
                      }else{
                        setUrlPopUp({url : res.payload , isOpen : true})  
                      }
                    } ).then(() => setLoading(false))
                  }}
                  />
                  </div>
                </div>
                <Table className='mt-8' dataSource={tripsOfTheDay} columns={columns} pagination={false} />

                {/* <CustomButton
                  btnType="extra"
                  icon="filter"
                  btnText="filter"
                  className='!h-9 ml-4 max-w-24 self-end'
                  handleSubmit={e => {
                    if (isFilterOpen) {

                      dispatch(setDashBoardQuery({ ...dashboardQuery, ...calculateDates(new Date()) }))
                    }
                    setIsFilterOpen(!isFilterOpen)
                  }}
                /> */}
              </div>
              {/* {
                isFilterOpen &&
                <Filters dashboardQuery={dashboardQuery} setDashBoardQuery={setDashBoardQuery} />
              } */}
            </div>
          </div>

        </>



      }
      <UrlPopup urlPop={urlPopUp} setUrlPopUp={setUrlPopUp}  />
    </>
  )
}
