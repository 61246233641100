import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import AdminLayoutContentWrapper from "../../layout/adminLayout/adminLayoutContentWrapper";
import FilterListSection from "../../components/FilterListSection";
import TripsManagementTable from "./table/TripsManagementTable";
import { Drawer } from "antd";
import { getTrips, setQuery, setTrip, getBusinessAccounts, getDrivers, getVehicles, getZones ,getTypes , getTripById } from './store/tripSlice'
import TripForm from './components/TripForm'
import CustomButton from '../../components/CustomButton/CustomButton'
import Filters from './components/Filters'



function ManageTrips() {
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const trip = useSelector((state) => state.trip.trip.trip);
  const query = useSelector((state) => state.trip.trip.query);
  const trips = useSelector((state) => state.trip.trip.trips);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const totalResults = useSelector((state) => state.trip.trip.totalResults);
  const [searchParams] = useSearchParams();
  const tripId = searchParams.get('tripId');

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getTrips(query));
    };
    fetchData();
  }, [dispatch, query]);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getBusinessAccounts({}));
      await dispatch(getDrivers({ page: 0, limit: 100000 , isActive: true }));
      await dispatch(getVehicles({ page: 0, limit: 100000 }));
      await dispatch(getZones({ page: 0, limit: 100000 }));
      await dispatch(getTypes());
    };
    fetchData();
  }, [dispatch]);
  
  useEffect(() => {
    if (tripId) {
      dispatch(getTripById(tripId));
      setIsDrawerOpen(true);
    }
  }, [tripId, dispatch]);

  const IS_SUPERVISOR = true;






  const defaultSort = {
    orderColumn: "createdAt",
    orderBy: "descend",
  }


  const setTextRef = useRef(null);
  // const dispatch = useAppDispatch(); 
  return (
    <div className="bg-layout h-full flex flex-col" >
      <FilterListSection
        setQuery={setQuery}
        searchField='firstName'
        leftComponent={<p className="font-bold text-lg mr-8">Trips</p>}
        filterComponent={
          <CustomButton
            btnType="extra"
            icon="filter"
            btnText="filter"
            className='!h-9 ml-4 max-w-24 '
            handleSubmit={e => {
              if (isFilterOpen) {
                const { businessaAccountId,  driverId, vehicleId,status, dropOffLocation , pickupLocation , date  ,time , ...rest } = query
                dispatch(setQuery({...rest, status: ''}))
              }
              setIsFilterOpen(!isFilterOpen)
            }}
          />
        }
        searchProps={{
          searchKey: "search",
          setTextRef: setTextRef,
          onTextChange: (e) => dispatch(setQuery(e ? { ...query, search: e, limit: 10, page: 1 } : { ...query, limit: 10, page: 1 })),
        }}
      />

      <AdminLayoutContentWrapper >
        {isFilterOpen &&
          <Filters
            query={query}
            setQuery={setQuery}

          />
        }
        <TripsManagementTable
          data={{ content: trips, totalRecords: totalResults }}
          query={query}
          setQuery={setQuery}
          handleEditVehicle={() => console.log("edit")}
          defaultSort={defaultSort}
          activeRowId={trip?._id} // Passing the drawerData prop
          // handleToggleVehicleStatus={handleToggleVehicleStatus}
          handleToggleVehicleStatus={(e) => console.log("toggle status", e)}
          IS_SUPERVISOR={IS_SUPERVISOR}
          setIsDrawerOpen={setIsDrawerOpen}
          setTrip={setTrip}
        />
      </AdminLayoutContentWrapper>

      <Drawer
        className={`form-drawer`}
        rootClassName="[&_.ant-drawer-header]:!pl-[24px] [&_.ant-drawer-title]:w-full"
        title={'Approve the Ride'}
        placement="right"
        closable={false}
        open={isDrawerOpen}
        // open={!isEmpty(data)}
        onClose={() => {
          dispatch(setTrip(null))
          setIsDrawerOpen(false)
        }}
        width='33%'
        destroyOnClose
      >
        <TripForm
          trip={trip}
          setIsDrawerOpen={setIsDrawerOpen}
          query={query}
        />
      </Drawer>

    </div>
  );
}
export default ManageTrips;