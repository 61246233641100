import { Header } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
import React from "react";
import "./styles.css";
import { Avatar, Dropdown, Menu, Space } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import { ROUTE_NAME_LOOKUP } from "../../../utils/constants";
import {
  Button,
} from 'antd';

const CustomHeader = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user.data);
  const currentPathName = `/${location?.pathname.split('/')[1]}`;
  const handleLogout = () => {
    localStorage.removeItem("jwt_access_token");
    window.location.href = "/signin";
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="header-container">
      <div className=" text-base flex w-full items-center">
        <p>{ROUTE_NAME_LOOKUP[currentPathName]}</p>
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <div className="ml-auto profile-main-container" style={{ float: 'right', paddingRight: '10px', cursor: 'pointer' }}>
            <Space>
              <Avatar
                size={40}
                src={user.imageUrl ? user.imageUrl : "https://via.placeholder.com/32"}
                icon={<UserOutlined />}
                shape="circle"
              />
              <p>{user.name}</p>
            </Space>
          </div>
        </Dropdown>
      </div>
      <div className=" flex gap-x-2">
      </div>
    </Header>
  );
};

export default CustomHeader;
