import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';

export const getZones = createAsyncThunk(
  'zoneApp/getZones',
  async (query, { dispatch }) => {
    const response = await axios.get('/zone'
      , {
      params:query
      }

    );
    await dispatch(setTotalResults(response.data.totle));
    await dispatch(setZones(response.data.zones));
    return response.data;
  }
);
export const saveZone = createAsyncThunk(
  'zoneApp/saveZone',
  async (data, { dispatch }) => {
    try {
      const response = await axios.post('/zone', data
      );
      await dispatch(getZones());
      return response.data;
      
    } catch (error) {
      console.log(error.response);
      return error.response;
      
    }
  }
);
export const getTypes = createAsyncThunk(
  'zoneApp/getTypes',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle/type' );
    await dispatch(setTypes(response.data.types));
    return response.data;
  }
);

export const updateZone = createAsyncThunk(
  'zoneApp/updateZone',
  async ({zoneId, data}, { dispatch }) => {
    const response = await axios.put(`/zone/${zoneId}`, data
    );
    await dispatch(getZones());
    return response.data;
  }
);
export const deleteVehicle = createAsyncThunk(
  'zoneApp/deleteVehicle',
  async ({vehicleId}, { dispatch }) => {
    const response = await axios.delete(`/vehicle/${vehicleId}`);
    await dispatch(getZones());
    return response.data;
  }
);
export const approveDriver = createAsyncThunk(
  'zoneApp/approveDriver',
  async (data, { dispatch }) => {
    const response = await axios.post('/admin/approveDriver', data
      //, {
      // params: {
      //   page: page,
      //   limit,
      //   userId,
      // },
      // }
    );
    await dispatch(getZones());
    return response.data;
  }
);
export const getCities = createAsyncThunk(
  'zoneApp/getCities',
  async (data, { dispatch }) => {
    const response = await axios.get('/public/cities/'
      //, {
      // params: {
      //   page: page,
      //   limit,
      //   userId,
      // },
      // }

    );
    await dispatch(setCities(response.data));
    return response.data;
  }
);

// export const getMyProducts = createAsyncThunk(
//   'productsApp/products/getMyProducts',
//   async ({ page, limit }) => {
//     const response = await axios.get('product/myProduct', {
//       params: {
//         page: page + 1,
//         limit,
//       },
//     });
//     return response.data;
//   }
// );

const zoneSlice = createSlice({
  name: 'zoneApp',
  initialState: {
    totalResults: 0,
    zone: null,
    data: [],
    types : [], 
    zones: [],
    cities: [],
    query: {
      page: 0,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'desc'
    }
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setZone: {
      reducer: (state, action) => {

        state.zone = action.payload ? action.payload : null;
      },
      prepare: zone => ({ payload: zone || null }),
    },
    setQuery: {
      reducer: (state, action) => {

        state.query = action.payload ? action.payload : {
          page: 0,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'desc'
        };
      },
      prepare: query => ({
        payload: query || {
          page: 0,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend'
        }
      }),
    },
    setCities: {
      reducer: (state, action) => {

        state.cities = action.payload ? action.payload : 0;
      },
      prepare: cities => ({ payload: cities || [] }),
    },
    setZones: {
      reducer: (state, action) => {
        state.zones = action.payload ? action.payload : 0;
      },
      prepare: zones => ({ payload: zones || [] }),
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getDrivers.fulfilled, (state, action) => {
  //       state.data = action.payload.data
  //     })

  // },
});

export const {
  setTotalResults,
  setZones,
  setCities,
  setQuery,
  setZone,
  setTypes,
} = zoneSlice.actions;

export default zoneSlice.reducer;

