import React, { useEffect, useState } from "react";
import loginImage from "../../assets/img/login_header.png"
import { useNavigate ,Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from "../../auth/store/loginSlice"
import { validateEmail, isEmpty } from '../../helper'
import MetaTag from "../../components/metaTag";
import { Box, TextField, Button, Checkbox  } from '@mui/material';
import PublicLayoutContentWrapper from "../../layout/publicLayout/PublicLayout";
import toast from "react-hot-toast";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, setLogin] = useState({
    email: '',
    password: '',
    rememberMe: false
  });
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const user = useSelector(({ auth }) => auth.user.data);
  useEffect(() => {
    if (user.id) {
      navigate("/dashboard");
    }
  }, [dispatch, navigate, user])

  

  const handleLogin = () => {
    const { email, password } = login;
    if (email.length === 0 && password.length === 0) {
      toast.error('Please enter email and password');
      return;
    }

    if (!validateEmail(email.trim())) {
      toast.error('Please enter a valid email address');
      return;
    }
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }

    dispatch(submitLogin({ email: email.trim(), password })).then((res) => {
      if (res.type === 'auth/login/loginError') {
        toast.error(res.payload)
      }
    });
  }
  return (
    <div className="	   ">
      <MetaTag />
      <PublicLayoutContentWrapper title="Login" description="Please enter your email and password to login" className="flex flex-col" headerImage={loginImage} >
        <div className="flex flex-col   w-full  mx-auto text-start">
          <Box
            component="form"
            className="flex flex-col gap-y-4 justify-center w-full  mx-auto "
            // sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
            noValidate
            autoComplete="off"
          >
            <TextField
              className="w-full"
              name="email"
              id="email"
              label="Email"
              variant="outlined"
              required
              placeholder="Enter Email"
              onChange={(e) => setLogin({ ...login, [e.target.name]: e.target.value })}
              color="secondary"
            />
            <TextField
              className="w-full"
              name="password"
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              required
              placeholder="Enter Password"
              color="secondary"
              onChange={(e) => setLogin({ ...login, [e.target.name]: e.target.value })}
            />
            <Button
              variant="contained"
              className="mb-0"
              sx={{ backgroundColor: '#24508d' }}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Box>
          <div className="flex justify-between mt-0 font-semibold text-black w-full mx-auto ">
          <Link to="/forgotpassword">ForgotPassword</Link>


          </div>
        </div>
      
      </PublicLayoutContentWrapper>
    </div>
  );
};

export default SignIn;
