import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Tabs,
    Button,
    Form,
    Input,
    Select,
    Table
} from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import './style.css'
import { saveZone, updateZone, setZone } from '../store/zoneSlice';
import { toast } from 'react-hot-toast';



export default function ZoneForm({ zone, setIsDrawerOpen }) {
    const [coordinates, setCoordinates] = React.useState([])
    const [zoneData, setZoneData] = React.useState({})
    const [buttonDisabled, setButtonDisabled] = React.useState(zone ? false : true)
    const [isPointsView, setIsPointsView] = React.useState(false)
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const [form] = Form.useForm();
    const [pointForm] = Form.useForm();
    useEffect(() => {
        if (zone) {
            form.setFieldsValue(zone);
            setCoordinates(zone.coordinates)
        } else {
            form.setFieldsValue({})
        }
    }, [dispatch, form, zone]);


    // const onSaveZone = () => {

    //     const data = { ...mainData, ...form.getFieldsValue(), coordinates }
    //     const { name, rank, number } = data
    //     if (!(name && name !== "")) {
    //         toast.error('Name is required')
    //     }
    //     if (!(rank && rank !== "")) {
    //         toast.error('Rank is required')
    //     }
    //     if (!(number && number !== "")) {
    //         toast.error('Number is required')
    //     }

    //     if (coordinates.length === 0) {
    //         toast.error('min one point is required')
    //         return;
    //     }

    //     setIsLoading(true)
    //     if (zone) {
    //         dispatch(updateZone({ data: { ...mainData, coordinates }, zoneId: zone._id })).then((data) => {
    //             if (!data.payload?.zone || data?.payload?.error || data?.error) {
    //                 toast.error('Creating Zone failed');
    //                 return;
    //             }
    //             toast.success('Zone Updated Successfully');
    //         }).finally(() => {
    //             setIsLoading(false)
    //             dispatch(setZone(null))
    //             setIsDrawerOpen(false);
    //         })
    //         return
    //     }
    //     dispatch(saveZone({ ...mainData, coordinates })).then((data) => {
    //         if (!data.payload?.zone || data?.payload?.error || data?.error) {
    //             toast.error('Creating Zone failed');
    //             return;
    //         }
    //         toast.success('Zone Created Successfully');
    //     }).finally(() => {
    //         setIsLoading(false)
    //         setIsDrawerOpen(false);
    //     })


    // };
    const onSaveZone = (values) => {
        const data = { ...values, coordinates }
        const { name, number } = data
        if (!(name && name !== "")) {
            toast.error('Name is required')
        }
        if (!(number && number !== "")) {
            toast.error('Number is required')
        }

        if (coordinates.length === 0) {
            toast.error('min one point is required')
            return;
        }
        setIsLoading(true)
        if (zone) {
            dispatch(updateZone({ data: { ...values, coordinates }, zoneId: zone._id })).then((data) => {
                if (!data.payload?.zone || data?.payload?.error || data?.error) {
                    toast.error('Creating Zone failed');
                    return;
                }
                toast.success('Zone Updated Successfully');
            }).finally(() => {
                setIsLoading(false)
                dispatch(setZone(null))
                setIsDrawerOpen(false);
            })
            return
        }
        dispatch(saveZone({ ...values, coordinates, rank: 13 })).then((data) => {
            if (!data.payload?.zone || data?.payload?.error || data?.error) {
                if (data?.payload?.data?.message) {
                    toast.error(data.payload.data.message);
                    return
                }
                toast.error('Creating Zone failed');
                return;
            }
            toast.success('Zone Created Successfully');
            setIsDrawerOpen(false);
        }).finally(() => {
            setIsLoading(false)
          
        })


    };

    const onAddPoint = (values) => {
        if (values.latitude == '' || values.longitude == '' || values.name.en == '' || values.name.ar == '' || values.name.en == undefined || values.name.ar == undefined || values.latitude == undefined || values.longitude == undefined) {
            toast.error('Please fill all fields')
            return;
        }
        setCoordinates([...coordinates, values])
        pointForm.resetFields();
    }

    function onChangeFields(changedFields, allFields) {
        const values = allFields.map(e => e.value)
        const number = allFields.find(e => e.name[0] === 'number').value

        if (values.some(e => e == undefined || e == '')) {
            setButtonDisabled(true)
        } else {

            if (+number) {
                setButtonDisabled(false)
            } else {
                setButtonDisabled(true)
            }
        }
    }

    const PointsTable = () => {
        const dataWithKeys = coordinates.map((coord, index) => ({
            ...coord,
            key: index, // If no unique key, use the index (not ideal for dynamic lists)
        }));
        const columns = [
            {
                title: 'Name En',
                dataIndex: 'nameEn',
                key: 'nameEn',
                render: (text, point) => <div key={point.name.en} className="flex gap-x-2">{point.name.en}</div>,
            },
            {
                title: 'Name Ar',
                dataIndex: 'nameAr',
                key: 'nameAr',
                render: (text, point) => <div key={point.name.ar} className="flex gap-x-2">{point.name.ar}</div>,
            },
            {
                title: 'longitude',
                dataIndex: 'longitude',
                key: 'longitude',
            },
            {
                title: 'latitude',
                dataIndex: 'latitude',
                key: 'latitude',
            },
            {
                title: "Delete",
                dataIndex: "edit",
                sorter: false,
                align: "center",
                width: 10,
                key: "edit",
                render: (_, record) => {
                    return (
                        <>
                            <DeleteOutlined
                                key={record._id}
                                onClick={() => {
                                    setCoordinates(dataWithKeys.filter((item) => item !== record))
                                }}
                                style={{ fontSize: "18px", marginRight: "8px" }}
                            />
                        </>
                    );
                },
            },
        ];

        return <div className='table-container flex '>
            <Table className='w-4/6 points-table' dataSource={dataWithKeys} columns={columns} />
        </div>
    }
    return (
        <div className='flex flex-col drawer-content '>
            <p className='form-subHeader'>{isPointsView ? 'Point Details' : 'Zone Details'}</p>

            <div className={`${isPointsView ? '' : 'hidden'}`}>
                <Form
                    form={pointForm}
                    colon={false}
                    labelAlign='left'
                    className='w-full self-start flex flex-col justify-center gap-y-0  px-8 pt-8  '
                    name="Info"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 300,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    autoComplete="off"
                    onFinish={onAddPoint}
                >
                    <Form.Item
                        label="Latitude"
                        name='latitude'
                        className='p-0 m-2'

                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Longitude"
                        name='longitude'
                        className='p-0 m-2'

                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Name English"
                        name={['name', 'en']}
                        className='p-0 m-2'

                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Name Arabic"
                        name={['name', 'ar']}
                        className='p-0 m-2'
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item className='flex justify-end'>
                        <Button type="primary" htmlType="submit">
                            Add Point
                        </Button>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                    </Form.Item>
                </Form>
                <PointsTable />
                <Form
                    form={form}
                    labelAlign='left'
                    className={`w-full flex flex-col justify-center gap-y-0 px-8 pt-8 `}
                    name="zoneForm"
                    colon={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 300,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    autoComplete="off"
                    onFinish={onSaveZone}
                >
                    <Form.Item
                        className='self-end'
                    >
                        <Button type="primary" htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                    </Form.Item>
                </Form>
            </div>



            <div className={`${isPointsView ? 'hidden' : ''}`}>
                <Form
                    form={form}
                    labelAlign='left'
                    className={`w-full flex flex-col justify-center gap-y-0 px-8 pt-8 `}
                    name="zoneForm"
                    colon={false}
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 300,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    autoComplete="off"
                    onFinish={onSaveZone}
                    onFieldsChange={(changedFields, allFields) => onChangeFields(changedFields, allFields)}
                >
                    <Form.Item
                        label="Name"
                        name='name'
                        className='p-0 m-2'
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        className='p-0 m-2'
                        label="Number"
                        name="number"
                        rules={[

                            {
                                pattern: /^[0-9]+$/,
                                message: 'Only numbers are allowed!',
                            },
                        ]}
                    >
                        <Input
                        />
                    </Form.Item>

                </Form>
                <Form.Item className='flex justify-end mr-20'>
                    <Button type="primary" htmlType="submit"
                        onClick={() => {
                            setIsPointsView(true)
                            setZoneData(form.getFieldsValue())
                        }}
                        disabled={buttonDisabled}
                    >
                        {zone ? 'Points Details' : 'Add Points'}
                    </Button>
                </Form.Item>
            </div>




        </div>
    )
}
