import React, { useState, useEffect, useMemo } from 'react'
import './style.css'
import { useSelector, useDispatch } from 'react-redux'
import MiniGraph from '../../assets/img/MiniGraph.svg'
import NegativeMiniGraph from '../../assets/img/negativeChart.svg'
import Arrow from '../../assets/img/arrow.svg'
import DownArrow from '../../assets/img/downArrow.svg'
import driversIcon from '../../assets/img/driversicon.svg'
import tripsIcon from '../../assets/img/tripsIcon.svg'
import vehiclesIcon from '../../assets/img/vehiclesIcon.svg'
import BarChart from './compnents/BarcharGraph';
import { Space, Select, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CalendarComponent from './compnents/CalendarComponent';
import MapComponent from './compnents/MapComponent';
import Filters from './compnents/Filters';
import CustomButton from '../../components/CustomButton/CustomButton'
import { setQuery, getTripCount } from './store/dashboardSlice'
import { getBusinessAccounts, getDrivers, getVehicles, getZones, getTypes, getDashData } from './store/dashboardSlice';
import Loader from '../../components/loader'
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';


export default function Dashboard() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const query = useSelector(state => state.dashboard.dashboard.query)
  const dashData = useSelector(state => state.dashboard.dashboard.dashData)
  const [topThree, setTopThree] = useState('topBusiness')
  const [calDate, setCalDate] = useState(calculateDates(new Date()))
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getBusinessAccounts({}));
      await dispatch(getDrivers({ page: 0, limit: 100000 }));
      await dispatch(getVehicles({ page: 0, limit: 100000 }));
      await dispatch(getZones({ page: 0, limit: 100000 }));
      await dispatch(getTypes());
      await dispatch(getDashData({
        query: {
          startDate: calDate.startDate,
          endDate: calDate.endDate
        }
      }));
    };
    setLoading(true)
    fetchData().then(() => setLoading(false));

  }, [dispatch]);
  const memoizedMapComponent = useMemo(() => <MapComponent />, []);

  function calculateDates(date) {
    const currentDate = date
    const startDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1));
    startDefaultDate.setUTCHours(0, 0, 0, 0); // Start of the day in UTC
    const endDefaultDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    endDefaultDate.setUTCHours(23, 59, 59, 999); // End of the day in UTC

    return {
      startDate: startDefaultDate.toISOString().split('T')[0],
      endDate: endDefaultDate.toISOString().split('T')[0]
    };
  };
  const getMonthName = (monthNumber) => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[monthNumber];
  };
  const increaseMonthByOne = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return newDate;
  };
  const decreaseMonthByOne = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - 1);
    return newDate;
  };

  const miniGraps={
    positive: {graph :MiniGraph,
      arrow: Arrow
    },
    negative: {graph : NegativeMiniGraph , arrow: DownArrow},

  }
  const calculatePercentage=(lastMonth, thisMonth)=>{
    return (((thisMonth - lastMonth) / lastMonth) * 100).toFixed(2) || 0
  }
  const [isFilterOpen, setIsFilterOpen] = React.useState(false)
  const mappingObj = {
    totalRevenue: { name: 'Total Revenue',positiveChange: dashData?.totalLastMonthRevenue < dashData?.totalRevenue ,  suffix: 'QAR' , img: true, percentage: calculatePercentage(dashData?.totalLastMonthRevenue, dashData?.totalRevenue) },
    totalNumberOfBusiness: { name: 'Total Number Of Business', positiveChange: dashData?.totalNumberOfBusinessLastMonth < dashData?.totalNumberOfBusiness , img: true,percentage: calculatePercentage(dashData?.totalNumberOfBusinessLastMonth, dashData?.totalNumberOfBusiness) },
    tripsThisMonth: { name: 'Trips This Month', positiveChange: dashData?.tripsLastMonth < dashData?.tripsThisMonth, img: true ,percentage: calculatePercentage(dashData?.tripsLastMonth, dashData?.tripsThisMonth) },
    registeredDrivers: { name: 'Registered Drivers', img: driversIcon },
    numberOfVehicles: { name: 'Number of Vehicles', img: vehiclesIcon },
    onGoingRides: { name: 'On Going Trips', img: tripsIcon },
  }

  useEffect(() => {
    dispatch(getTripCount(query))
  }, [query])


  const firstRowData = {
    totalRevenue: dashData?.totalRevenue,
    totalNumberOfBusiness: dashData?.totalNumberOfBusiness,
    tripsThisMonth: dashData?.tripsThisMonth,
  }
  const secondRowData = {
    registeredDrivers: dashData?.registeredDrivers,
    numberOfVehicles: dashData?.numberOfVehicles,
    onGoingRides: dashData?.onGoingRides,
  }

  const graphData = {
    revenue: dashData?.totalRevenue / 12 || 0,
  }
  const firstRow = Object.keys(firstRowData).map((key, index) => {
    return <div className='minor-container' key={key}>
      <div  >
        <div className='flex'>
          {mappingObj[key].img && <img src={mappingObj[key].positiveChange ? MiniGraph : NegativeMiniGraph} alt='mini-graph' />}
          <p className='upper-text'>{`${firstRowData[key]} ${mappingObj[key]?.suffix || ''}`} </p>
          <img className='mb-4' src={mappingObj[key].positiveChange ? Arrow : DownArrow} alt='mini-graph' />

          <span className={`${mappingObj[key].positiveChange ? 'text-green-500' : 'text-red-500'} mt-2`}>({mappingObj[key]?.percentage}%)</span>
        </div>
        <div>
          <p className='under-text'>{mappingObj[key].name}</p>
        </div>
      </div>
    </div>
  })

  const secondRow = Object.keys(secondRowData).map((key, index) => {
    return <div className='minor-container' key={key}>
      <div   >
        <div className='number-container'>
          <img src={mappingObj[key].img} alt='mini-graph' />
          <p className='upper-text'>{`${secondRowData[key]} ${mappingObj[key]?.suffix || ''}`} </p>
        </div>
        <p className='under-text'>{mappingObj[key].name}</p>
      </div>
    </div>
  })

  const data = [];
  const date = new Date();

  for (let i = 0; i < 12; i++) {
    date.setMonth(i);
    const value = i === 10 ? dashData?.totalRevenue : 0;
    data.push({ value, date: date.toLocaleString('default', { month: 'short' }) }); // Get the full month name
  }

  const config = {
    data,
    xField: 'date',
    yField: 'value',
    xAxis: {
      type: 'timeCat', // for date-based x-axis
      tickCount: 5,
    },
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    yAxis: {
      label: {
        formatter: (v) => `${v}`, // format y-axis labels if needed
      },
    },

    smooth: true, // for a smooth line chart
    height: 300,
  };


  const intervals = [
    { range: '00:00-01:00', count: 0 },
    { range: '01:00-02:00', count: 0 },
    { range: '02:00-03:00', count: 0 },
    { range: '03:00-04:00', count: 0 },
    { range: '04:00-05:00', count: 0 },
    { range: '05:00-06:00', count: 0 },
    { range: '06:00-07:00', count: 0 },
    { range: '07:00-08:00', count: 0 },
    { range: '08:00-09:00', count: 0 },
    { range: '09:00-10:00', count: 0 },
    { range: '10:00-11:00', count: 0 },
    { range: '11:00-12:00', count: 0 },
    { range: '12:00-13:00', count: 0 },
    { range: '13:00-14:00', count: 0 },
    { range: '14:00-15:00', count: 0 },
    { range: '15:00-16:00', count: 0 },
    { range: '16:00-17:00', count: 0 },
    { range: '17:00-18:00', count: 0 },
    { range: '18:00-19:00', count: 0 },
    { range: '19:00-20:00', count: 0 },
    { range: '20:00-21:00', count: 0 },
    { range: '21:00-22:00', count: 0 },
    { range: '22:00-23:00', count: 0 },
    { range: '23:00-24:00', count: 0 },
  ];

  dashData?.tripsToday.forEach(trip => {
    const startHour = new Date(trip.startAt).getUTCHours();
    intervals[startHour].count += 1;
  });

  const intervalData = intervals.map(interval => interval.count);
  return (

    <>
      {!dashData ? <Loader /> :

        <div className='main-container'>
          {firstRow}
          {secondRow}
          <div className='graph-main-container'>
            <p className='card-title'>Trips of the Day</p>
            <LineChart
              xAxis={[{ data: intervals.map((_, index) => index + 1) }]}
              series={[
                {
                  data: intervalData,
                  color: '#274D91',
                },
              ]}
              height={250}

            />
            <p className='text-center text-xl font-bold text-[#274D91]'>total {dashData?.tripsToday.length} Rides</p>
            {/* <Line {...config} /> */}
          </div>
          <div className='graph-main-container'>
            <p className='card-title'>Revenue</p>
            <BarChart name='revenue' dataset={data} yField='value' xField='date' />
            <div className='flex justify-between under-graph'>
              <p>Average</p><p>{graphData.revenue.toFixed(2)} QAR</p>
            </div>
          </div>
          <div className='container-b'>
            <div className='container-c ' >
              <div className='submain-cards ranking p-8 '>
                <div className='submain-header flex'>
                  <p className='card-title'>Ranking</p>
                  <Space wrap className='card-filter'>
                    <Select
                      bordered={false}
                      className="ml-4 card-select-item"
                      defaultValue="topBusiness"
                      // style={{ width: 120 }}
                      onChange={e => setTopThree(e)}
                      placeholder={`Select a `}
                      options={[
                        { label: 'Bussiness Accounts', value: 'topBusiness', },
                        { label: 'Vehicle Type', value: 'topVehicleType', }
                      ]}
                    />
                  </Space>
                </div>
                <div>
                  {dashData[topThree].map((ele, index) => {
                    return <div className='flex rank-container'>
                      <div className='flex '>
                        <p className='rank-number'> {index + 1}</p>
                        <p>
                          <p className='rank-name'>
                            {topThree === 'topBusiness' ? ele?.date.businessAccount : `${ele?.date.make} ${ele?.date.model}`}
                          </p>
                          <p className='rank-subname'>
                            {topThree === 'topBusiness' ? 'Bussiness Account' : `Vehicle Type`}
                          </p>
                        </p>
                      </div>

                      <p className='rank-count'>
                        {ele.count} {topThree === 'topBusiness' ? 'Trips' : 'Vehicles'}
                      </p>
                    </div>
                  })}
                </div>
              </div>
              <div className='submain-cards flex flex-col justify-center piechart'>
                {/* <p className='card-title '>November 2024</p> */}

                <div className='font-bold text-xl flex w-[200px] justify-between cal-header-title items-center gap-x-2 '>
                  <Button
                    style={{ border: 'none', background: 'none', boxShadow: 'none' }}
                    type="default"
                    disabled
                    icon={<LeftOutlined />}
                    onClick={() => {
                      const newDate = decreaseMonthByOne(new Date(calDate.startDate));
                      dispatch(setQuery({ ...query, ...calculateDates(newDate) }))
                      setCalDate(calculateDates(newDate));
                    }}
                  />
                  <p>
                    November
                  </p>
                  <Button
                    type="default"
                    icon={<RightOutlined />}
                    style={{ border: 'none', background: 'none', boxShadow: 'none' }}
                    disabled
                    onClick={() => {
                      const newDate = increaseMonthByOne(new Date(calDate.startDate));
                      setCalDate(calculateDates(newDate));
                      dispatch(setQuery({ ...query, ...calculateDates(newDate) }))
                    }}
                  />
                </div>

                <PieChart
                  series={[
                    {
                      data: [
                        { id: 0, value: dashData.canceledTrips, color: '##152A50' },
                        { id: 1, value: dashData.completedTrips, color: '#274D91' },
                      ],
                      innerRadius: 30,
                      outerRadius: 100,
                      paddingAngle: 2,
                      cornerRadius: 5,
                      startAngle: -45,
                      // endAngle: 225,
                      cx: '80%',
                      cy: '50%',
                    }
                  ]}

                />
                <div className='flex items-center'>
                  <div className='w-[10px] h-[10px] bg-[#274D91] mr-2 rounded-full '>

                  </div>
                  <p className='mr-auto font-bold'>Completed Trips</p>
                  <p className='font-bold'>{(dashData.completedTrips / (dashData.canceledTrips + dashData.completedTrips) * 100).toFixed(0)}%</p>
                </div>
                <div className='flex items-center'>
                  <div className='w-[10px] h-[10px] bg-[#152A50] mr-2 rounded-full'>

                  </div>
                  <p className='mr-auto font-bold'>Canceled Trips</p>
                  <p className='font-bold'>{(dashData.canceledTrips / (dashData.canceledTrips + dashData.completedTrips) * 100).toFixed(0)}%</p>
                </div>


              </div>
            </div>
            <div className='map-container'>
              {memoizedMapComponent}
            </div>
          </div>
          <div className="calender-container">
            <div className='flex w-full justify-between '>

              <div className='font-bold text-xl flex w-[200px] justify-between cal-header-title items-center gap-x-2 '>
                <Button
                  style={{ border: 'none', background: 'none', boxShadow: 'none' }}

                  type="default"
                  icon={<LeftOutlined />}
                  onClick={() => {
                    const newDate = decreaseMonthByOne(new Date(calDate.startDate));
                    dispatch(setQuery({ ...query, ...calculateDates(newDate) }))
                    setCalDate(calculateDates(newDate));
                  }}
                />
                <p>
                  {getMonthName(calDate.startDate.split('-')[1] - 1)}
                </p>
                <Button
                  type="default"
                  icon={<RightOutlined />}
                  style={{ border: 'none', background: 'none', boxShadow: 'none' }}

                  onClick={() => {
                    const newDate = increaseMonthByOne(new Date(calDate.startDate));
                    setCalDate(calculateDates(newDate));
                    dispatch(setQuery({ ...query, ...calculateDates(newDate) }))
                  }}
                />
              </div>
              <CustomButton
                btnType="extra"
                icon="filter"
                btnText="filter"
                className='!h-9 ml-4 max-w-24 self-end'
                handleSubmit={e => {
                  if (isFilterOpen) {

                    dispatch(setQuery({ ...query, ...calculateDates(new Date()) }))
                  }
                  setIsFilterOpen(!isFilterOpen)
                }}
              />
            </div>
            {
              isFilterOpen &&
              <Filters query={query} setQuery={setQuery} />
            }
            <CalendarComponent date={calDate} query={query} />
          </div>
        </div>


      }

    </>
  )
}
