// import ImageName from "@/components/listings/image-name";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import {  useDispatch} from "react-redux";
import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import { MoreOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import CustomPopconfirm from "../../../components/customPopconfirm/CustomPopconfirm";
import {updateAdmin} from "../store/adminUserSlice"


const AdminUsersManagementTable = ({
  data,
  defaultSort,
  activeRowId,
  setAdminUser,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };

  const columns = [
    {
      title: 'Name', 
      dataIndex: 'name', 
      key: 'name',
      sorter: false,
      render: (text, adminUser) => RenderColumnComponent(true)(adminUser.name),
    },
    {
      title: 'Email', 
      dataIndex: 'email', 
      sorter: false,
      key: 'email',
      render: (text, adminUser) => RenderColumnComponent(true)(adminUser.email),
    },
    {
      sorter: false,
      title: 'Role', 
      dataIndex: 'role', 
      key: 'role',
      render: (text, adminUser) => RenderColumnComponent(true)(adminUser.role),
    },
    {
      title: "Activation", //"Active/Inactive",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_ , admin) => {
        return (
          <CustomPopconfirm
            title={
    `Confirm ${admin?.isActive ? 'Deactivate' : 'Activate'} this admin?` } 
            onConfirm={()=> dispatch(updateAdmin({data: {...admin , isActive : !admin.isActive} , query }))}
            isChecked={admin?.isActive}
            autoState={true}
          />
        );
      },
    },
    {
      title: "View/Edit", 
      dataIndex: "",
      align: "center",
      sorter: false,
      render: (_, record) => (
        <Tooltip>
          <div
            className=" cursor-pointer underline text-primary text-primary-dark"
            onClick={()=> {
              dispatch(setAdminUser(record))
              setIsDrawerOpen(true)}}
          >
            <MoreOutlined  style={{ fontSize: "18px", marginRight: "8px" }} />
          </div>
        </Tooltip>
      ),
    },
  ];
 
  return (
    <CustomTable
      data={data?.content}
      elementsName='Admin Users'
      columns={
        columns?.map((column) => ({
          ...column,
          ...((column ).dataIndex !== "status" &&
            (column ).sorter !== false && {
              ...columnSortProps((column ).dataIndex),
            }),
        })) 
      }
      scrollPosition={IS_SUPERVISOR ? 27 : 21}
      scroll={true}
      pagination={true}
      totalRecords={data?.totalRecords} 
      activeRowId={activeRowId}
      query={query}
      setQuery={setQuery}
    />
  );
};

export default AdminUsersManagementTable;
