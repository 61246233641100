import { createSlice, createAsyncThunk, } from "@reduxjs/toolkit";
import axios from 'axios';

export const getTrips = createAsyncThunk(
  'tripApp/getTrips',
  async (query, { dispatch }) => {
    const response = await axios.get('/trip/adminList'
      , {
        params: {
          ...query,
          page: query.page - 1,
        }
      }

    );
    await dispatch(setTotalResults(response.data.total));
    await dispatch(setTrips(response.data.trips));
    return response.data;
  }
);

export const getTripById = createAsyncThunk(
  'tripApp/getTripById',
  async (tripId, { dispatch }) => {
    try {
      const response = await axios.get(`/trip/adminList/${tripId}`
      );
      if(response.data.error){
        throw new Error(response.data)
      }
      if (response.data.trip) {
        await dispatch(setTrip(response.data.trip));
      }
      return response.data;
      
    } catch (error) {
      return error
    }
  }
);

export const getBusinessAccounts = createAsyncThunk(
  'tripApp/getBusinessAccounts',
  async (query, { dispatch }) => {
    const response = await axios.get('/business'
      , {
        params: {
          limit: 100000,
          page: 0,
          orderBy: 'name',
          orderDirection: 'descend',
        }
      }

    );
    await dispatch(setBusinessAccounts(response.data.accounts));
    return response.data;
  }
);

export const checkAvailability = createAsyncThunk(
  'tripApp/checkAvailability',
  async ({ startDate, endDate, driverId, vehicleId }, { dispatch }) => {
    try {
      const response = await axios.post('/admin/checkAvailability',
        { startDate, endDate, driverId, vehicleId }
      );
      return response.data;

    } catch (error) {
      console.log("error", error);
      return error
    }
  }
);
export const getZones = createAsyncThunk(
  'tripApp/getZones',
  async (query, { dispatch }) => {
    const response = await axios.get('/zone'
      , {
        params: query
      }

    );
    await dispatch(setZones(response.data.zones));
    return response.data;
  }
);


export const getTypes = createAsyncThunk(
  'tripApp/getTypes',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle/type');
    await dispatch(setTypes(response.data.types));
    return response.data;
  }
);
export const getDrivers = createAsyncThunk(
  'tripApp/getDrivers',
  async (query, { dispatch }) => {
    const response = await axios.get('/admin/drivers'
      , {
        params: query,
      }

    );
    await dispatch(setDrivers(response.data.drivers));
    return response.data;
  }
);

export const getVehicles = createAsyncThunk(
  'tripApp/getVehicles',
  async (query, { dispatch }) => {
    const response = await axios.get('/vehicle'
      , {
        params: query
      }

    );
    await dispatch(setVehicles(response.data.vehicles));
    return response.data;
  }
);
export const assigenTrip = createAsyncThunk(
  'tripApp/assigenTrip',
  async ({ tripId, vehicleId, driverId, estimationDate }, { dispatch }) => {
    try {
      const response = await axios.post(`/trip/assigne/${tripId}`
        ,
        { vehicleId, driverId, estimationDate }
      );
      await dispatch(getTrips({
        page: 1,
        limit: 10,
        orderBy: 'createdAt',
        orderDirection: 'descend',
        status: '',
      }));
      return response.data;

    } catch (error) {
      console.log("error", error);
      return { error }

    }
  }

);
export const changePrice = createAsyncThunk(
  'tripApp/changePrice',
  async ({ tripId ,price , query}, { dispatch }) => {
    try {
      const response = await axios.post(`/trip/changePrice/${tripId}`
        ,
        { price }
      );
      await dispatch(getTrips(query));
      return response.data;

    } catch (error) {
      console.log("error", error);
      return { error }

    }
  }

);



const tripSlice = createSlice({
  name: 'tripApp',
  initialState: {
    totalResults: 0,
    trips: [],
    trip: null,
    query: {
      page: 1,
      limit: 10,
      orderBy: 'createdAt',
      orderDirection: 'descend',
      status: '',
    },
    vehicles: [],
    drivers: [],
    businessAccounts: [],
    zones: [],
    types: [],
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {

        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
    setTrip: {
      reducer: (state, action) => {

        state.trip = action.payload ? action.payload : null;
      },
      prepare: trip => ({ payload: trip || null }),
    },
    setTypes: {
      reducer: (state, action) => {

        state.types = action.payload ? action.payload : null;
      },
      prepare: types => ({ payload: types || null }),
    },
    setZones: {
      reducer: (state, action) => {

        state.zones = action.payload ? action.payload : null;
      },
      prepare: zones => ({ payload: zones || null }),
    },
    setQuery: {
      reducer: (state, action) => {
        state.query = action.payload ? action.payload : {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        };
      },
      prepare: query => ({
        payload: query || {
          page: 1,
          limit: 10,
          orderBy: 'createdAt',
          orderDirection: 'descend',
          status: '',

        }
      }),
    },

    setTrips: {
      reducer: (state, action) => {
        state.trips = action.payload ? action.payload : 0;
      },
      prepare: trips => ({ payload: trips || [] }),
    },
    setBusinessAccounts: {
      reducer: (state, action) => {
        state.businessAccounts = action.payload ? action.payload : 0;
      },
      prepare: businessAccounts => ({ payload: businessAccounts || [] }),
    },
    setVehicles: {
      reducer: (state, action) => {
        state.vehicles = action.payload ? action.payload : 0;
      },
      prepare: vehicles => ({ payload: vehicles || [] }),
    },
    setDrivers: {
      reducer: (state, action) => {
        state.drivers = action.payload ? action.payload : 0;
      },
      prepare: drivers => ({ payload: drivers || [] }),
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getDrivers.fulfilled, (state, action) => {
  //       state.data = action.payload.data
  //     })

  // },
});

export const {
  setTotalResults,
  setTrips,
  setQuery,
  setTrip,
  setVehicles,
  setBusinessAccounts,
  setDrivers,
  setZones,
  setTypes,
} = tripSlice.actions;

export default tripSlice.reducer;

