import { Layout } from "antd";
import React from "react";
import CustomHeader from "./customHeader/CustomHeader";
import CustomSideBar from "./sideBar/CustomSideBar";
import './style.css'
import { Outlet } from "react-router-dom";
const { Content } = Layout;

const AdminLayout = ({ children }) => {
  return (
    <Layout className=" bg-layout  min-h-screen" hasSider={true}>
      <CustomSideBar />
      <Layout className="bg-inherit p-3">
        <CustomHeader />
        <Content className={`bg-white rounded-layout mt-2`}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
