import React, { useEffect, useState } from "react";
import loginImage from "../../assets/img/login_header.png"
import { useNavigate, useLocation  } from "react-router-dom"; // Added useLocation here
import { useDispatch, useSelector } from 'react-redux';
import { resetAdminPassword , setResetOtpToken } from "../../auth/store/loginSlice"
import { validateEmail, isEmpty } from '../../helper'
import MetaTag from "../../components/metaTag";
import { Box, TextField, Button } from '@mui/material';
import PublicLayoutContentWrapper from "../../layout/publicLayout/PublicLayout";
import toast from "react-hot-toast";

const OTP = () => {
    const navigate = useNavigate();
    const {resetPasswordToken} = useSelector(({ auth }) => auth.login);
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const user = useSelector(({ auth }) => auth.user.data);
    const [otp, setOtp] = useState(['', '', '', '']);
    useEffect(() => {
        if (user.id) {
            navigate("/");
        return;
        }
        if (!resetPasswordToken) {
            navigate("/forgot-password");
        }
    }, [dispatch, navigate, user, resetPasswordToken])



    const handleChangePassword = () => {
        if (password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            return;
        }

        // Validate password contains at least one uppercase letter
        if (!/[A-Z]/.test(password)) {
            toast.error('Password must contain at least one uppercase letter');
            return;
        }

        // Validate password matches passwordCheck
        if (password !== passwordCheck) {
            toast.error('Passwords do not match');
            return;
        }

        dispatch(resetAdminPassword({ password ,resetPasswordToken})).then((res) => {
            if(!res?.payload?.error){
                toast.success('Password Changed Successfully');
                navigate("/signin");
            }else{
                toast.error('Something went wrong, Please try again later');
            }
            
         
        });
    }
  
   
    return (
        <div className="">
            <MetaTag />
            <PublicLayoutContentWrapper title="Change Password" description="Please enter recived Password " className="flex flex-col items-center" headerImage={loginImage} >
                <div className="flex flex-col   w-full  mx-auto text-start">
                <Box
                        component="form"
                        className="flex flex-col gap-y-4 justify-center w-full  mx-auto  "
                        // sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            className="w-full"
                            name="password"
                            id="password"
                            label="New Password"
                            variant="outlined"
                            required
                            placeholder="Set New Password"
                            onChange={(e) => setPassword( e.target.value)}
                            color="secondary"
                        />
                        <TextField
                            className="w-full"
                            name="paswordCheck"
                            id="passwordCheck"
                            label="Retype New Password"
                            variant="outlined"
                            required
                            placeholder="Retype New Password"
                            onChange={(e) => setPasswordCheck( e.target.value)}
                            color="secondary"
                        />
                        <Button
                            variant="contained"
                            className="mb-0"
                            sx={{ backgroundColor: '#1c0249' }}
                            onClick={handleChangePassword}
                        >
                            Change Password
                        </Button>
                    </Box>

                </div>
            </PublicLayoutContentWrapper>

        </div>
    );
};

export default OTP;
