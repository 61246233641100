import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Form,
    Select,
    Input,
    TimePicker,
} from 'antd';
import moment from 'moment';
import { getDrivers, getVehicles, assigenTrip, setTrip, checkAvailability , changePrice } from '../store/tripSlice';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';


export default function TripForm({ trip, setIsDrawerOpen , query }) {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [estimationDate, setEstimationDate] = React.useState({ startDate: '', endDate: '' });
    const [tripDetails, setTripDetails] = React.useState({});
    const [form] = Form.useForm();
    useEffect(() => {
        dispatch(getVehicles({}));
        dispatch(getDrivers({
            page: 0,
            limit: 100000,
            status: 'approved'
        }));
        if (trip) {
            const prepareDate = (data) => {
                const { businessAccount, note, rider, startAt, vehicle, zoneFrom, zoneTo, status, id } = data;

                const preparedData = {
                    'Business Account': businessAccount.name,
                    'Note': note,
                    'Rider Name': rider.name,
                    'Rider mobile number': rider.mobileNumber,
                    'Room Number': rider.roomNumber,
                    'Status': status,
                    'Pickup Time': moment(startAt).format('MMMM Do YYYY, h:mm a'),
                    "Vehicle": vehicle.vehicleType.make.en + '-' + vehicle.vehicleType.model.en,
                    'Zone From': zoneFrom.name,
                    'Pickup Location': zoneFrom.coordinates.name.en,
                    'Zone To': zoneTo.name,
                    'Drop-off location': zoneTo.coordinates.name.en,
                    "id": id,
                    'startDate': data.estimationDate ? dayjs(new Date(data.estimationDate.startAt)) : dayjs(new Date(startAt)),
                    'endDate': data.estimationDate ? dayjs(new Date(data.estimationDate.endAt)) : '',
                    price: data.price

                }
                return preparedData;
            };
            const one = prepareDate(trip);
            setEstimationDate({ ...estimationDate, startDate: one.startDate, endDate: one.endDate });
            setTripDetails(one);
            form.setFieldsValue(one)
            trip.vehicle.id && trip.driver && form.setFieldsValue({ ...one, vehicleId: trip.vehicle.id, driverId: trip.driver.id });
        }
    }, [dispatch, trip, form]);
    const drivers = useSelector((state) => state.trip.trip.drivers);
    const vehicles = useSelector((state) => state.trip.trip.vehicles);
    const assignTrip = async (values) => {
        setIsLoading(true);
        dispatch(assigenTrip({ ...values, tripId: trip._id, estimationDate: { startAt: estimationDate.startDate, endAt: estimationDate.endDate } }))
            .then((res) => {
                if (res.payload.error) {
                    if (res?.payload?.error?.response?.data?.message) {
                        toast.error(res.payload.error.response.data.message);
                    } else {
                        toast.error('Something went wrong');
                    }
                    setIsLoading(false)
                } else {
                    form.resetFields();
                    dispatch(setTrip(null));
                    setIsDrawerOpen(false);
                    toast.success('Trip Assigned Successfully');
                    setIsLoading(false)

                }
            })

    };
    const onSetTime = (e, name) => {
        const date = new Date(trip.startAt)
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // Months are zero-indexed
        const day = date.getUTCDate();
        const assignedDateFormated = new Date(e)
        const newHour = assignedDateFormated.getHours();
        const newMinute = assignedDateFormated.getMinutes();
        const newDate = new Date(year, month, day, newHour, newMinute);
        setEstimationDate({ ...estimationDate, [name]: new Date(newDate).toISOString() });
    };
    const TripDetails = () =>
        <Form
            form={form}
            colon={false}
            labelAlign='left'
            className='w-full items-center gap-y-0   '
            name="details"
            labelCol={{
                span: 8,
                align: 'left'
            }}
            wrapperCol={{
                span: 13,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={assignTrip}
        >

            {renderFormFields(tripDetails)}
        </Form>
   const PriceForm = () => (
      <Form.Item className="flex justify-center">
        <Button
          type="primary"
          disabled={isLoading}
          onClick={() => {
            if(isNaN(+form.getFieldsValue().price) || +form.getFieldsValue().price < 0 || !Number.isInteger(+form.getFieldsValue().price)) {
                toast.error('Please enter a valid price');
                return;
            }
            dispatch(changePrice({ tripId:trip._id  , price: +form.getFieldsValue().price , query})).then(res =>{
                if (res.payload.error) {
                    if (res?.payload?.error?.response?.data?.message) {
                        toast.error(res.payload.error.response.data.message);
                    } else {
                        toast.error('Something went wrong');
                    }
                    setIsLoading(false)
                } else {
                    form.resetFields();
                    dispatch(setTrip(null));
                    setIsDrawerOpen(false);
                    toast.success('Price Changed Successfully');
                    setIsLoading(false)
                }
            });     
          }}
        >
          Change price
        </Button>
      </Form.Item>
  );

    const renderFormFields = (data) => {
        return Object.keys(data).filter(e => !['startDate', 'endDate'].includes(e)).map(key => (
            <Form.Item hidden={key === 'id'} name={key} label={key} key={key}>
                {typeof data[key] === 'object' ? renderFormFields(data[key]) : (
                    <Input disabled={key !== 'price'} value={data[key]} onClick={(e) => e.preventDefault()} />
                )}
            </Form.Item>
        ));
    };
    const checkVehicleAvailability = (e) => {
        dispatch(checkAvailability({ startDate: estimationDate.startDate, endDate: estimationDate.endDate, vehicleId: e }))
            .then(res => {
                if (res.payload.isAvailable) {
                    toast.success('Vehicle is available');
                } else {
                    toast.error('Vehicle is not available');
                }
            })
    }
    const checkDriverAvailability = (e) => {
        dispatch(checkAvailability({ startDate: estimationDate.startDate, endDate: estimationDate.endDate, driverId: e }))
            .then(res => {
                if (res.payload.isAvailable) {
                    toast.success('Driver is available');
                } else {
                    toast.error('Driver is not available');
                }
            })
    }
    const TripAssignForm = () =>

        <Form
            form={form}
            colon={false}
            labelAlign='left'
            className='w-full flex flex-col items-center   '
            name="checkandAssign"
            labelCol={{
                span: 8,
                align: 'left'
            }}

            wrapperCol={{
                span: 18,
            }}
            style={{
                maxWidth: 600,
            }}
            autoComplete="off"
            onFinish={assignTrip}
        >
            <Form.Item
                name='id'
                hidden
            />
            <div className='flex gap-x-4'>
                <Form.Item
                    label="start"
                    name='startDate'
                    className='p-0 w-full'

                >
                    <TimePicker
                        onChange={(e) => onSetTime(e, 'startDate')}
                        value={estimationDate.startDate}
                        format={'HH:mm'}
                    />
                </Form.Item>
                <Form.Item
                    label="end"
                    name='endDate'
                    className='p-0 w-full'
                >
                    <TimePicker
                        name='endDate'
                        onChange={(e) => onSetTime(e, 'endDate')}
                        value={estimationDate.endDate}
                        format={'HH:mm'}

                    />
                </Form.Item>

            </div>

            <Form.Item
                label="Vehicle"
                name='vehicleId'
                className='p-0 m-2 w-full'
            >
                <Select
                    disabled={isLoading || !estimationDate.startDate || !estimationDate.endDate}
                    options={
                        vehicles.map((vehicle) => {
                            return { value: vehicle._id, label: `${vehicle?.typeDetails?.make?.en || 'n/a'}-${vehicle?.typeDetails?.model?.en || 'n/a'}-${vehicle.color}-${vehicle.licensePlateNumber}` }
                        })
                    }
                    onChange={(e) => checkVehicleAvailability(e)}
                />
            </Form.Item>
            <Form.Item
                label="Driver"
                name='driverId'
                className='p-0 m-2 w-full'
            >
                <Select
                    disabled={isLoading || !estimationDate.startDate || !estimationDate.endDate}
                    initialvalue=""
                    options={
                        drivers.map((driver) => {
                            return { value: driver._id, label: `${driver.firstName} ${driver.lastName}` }
                        })
                    }
                    onChange={(e) => checkDriverAvailability(e)}

                />
            </Form.Item>
            {(trip && !['completed', 'canceled'].includes(trip?.status)) && <Form.Item className='p-0 m-2 w-full'
                label={
                    <span className="text-sm font-medium flex relative top-1">
                        Alternative Vehicle
                    </span>
                }
            >
                <div className='flex p-2 justify-center items-center gap-4 self-stretch rounded-md border-2 border-[var(--Colors-Border-Action,#274D91)]"'
                    onClick={() => window.open('/manage-vehicles?fromOpen=true', '_blank')}
                    style={{ cursor: 'pointer' }}>
                    <PlusOutlined />
                </div>
            </Form.Item>}
            {
                !['completed', 'canceled'].includes(trip?.status) &&
                <Form.Item className='flex justify-center'>
                    <Button type="primary" htmlType="submit" disabled={isLoading}>
                        Assign
                    </Button>
                </Form.Item>
            }

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
            </Form.Item>
        </Form>
    return (
        <div className='flex flex-col'>
            <p className='form-subHeader'>Ride Information</p>
            <div className='w-full flex flex-col px-8 pt-8  '>
                <TripDetails />
                <PriceForm />
            </div>
            <p className='form-subHeader'>Ride Information</p>
            <div className='w-full flex flex-col px-8 pt-8 '>
                <TripAssignForm />
            </div>


        </div>
    )
}
