import React , {useEffect ,useState } from 'react'
import { useParams , useNavigate } from 'react-router-dom';
import { useSelector , useDispatch } from 'react-redux';
import "./style.css";
import {getTypes} from '../store/businessAccountSlice';
import { Button } from "antd";
import {setPricesPopup} from '../store/businessAccountSlice';
import PricePop from './components/PricePop';

export default function BusinessAccountPriceList() {
  const [isLoading, setIsisLoading] = useState(true); 
  const dispatch = useDispatch();
  const { accountId } = useParams(); 
  const navigate = useNavigate(); 
  const {businessAccount} = useSelector((state) => state.businessAccount.businessAccount);
  const {types} = useSelector((state) => state.businessAccount.businessAccount);
 
  const typesList = new Set((types || []).map(e => {
    if(e?.make?.en){
      return e.make.en
    }else{
      return null
    }
  }))

  useEffect(() => {
    dispatch(getTypes());
    setIsisLoading(true);
    if (!isLoading && !businessAccount) {
      setIsisLoading(false);

      navigate('/manage-business-accounts');
    }
    setIsisLoading(false);

  }, [accountId, businessAccount, dispatch, isLoading, navigate]);

  if (isLoading) {
    return <div>isLoading...</div>;
  }

  if (!businessAccount) {
    return null; // or a fallback UI while navigating
  }


  return (
    <div className='pricing-major'>
      
          <dev className='pricing-main'>
      <p className='subpage-header'>{businessAccount.name}<span>{'> '}Business Details</span></p>
        {
          [...typesList].map((type) => {
            return (
              <>
             <p className='subpage-subHeader'>{type || 'N/A'}</p>
             <div className='type-major-container'>
           {   types.filter(e => e.make?.en === type).map((e) => {
             return (
                 <Button  className='price-element-container' type="primary"
                 onClick={() => dispatch(setPricesPopup({data: e , isModalOpen: true}))}
                 >{e?.make?.en ?  `${e?.make?.en} ${e?.model?.en}` : 'N/A'}
                 </Button>
           
              )
            })}
              </div>
            </>
            )
          })
        }

          </dev>

      <PricePop accountId={accountId}/>
      </div>
  )
}
