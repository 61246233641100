import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import AdminUsers from "./pages/manageAdminUsers/AdminUsers";
import Home from "./pages/Home/Home";
import ManageDrivers from './pages/manageDrivers/ManageDrivers'
import ManageVehicles from "./pages/manageVehicles/ManageVehicles";
import SignIn from "./pages/Authentication/SignIn";
import OTP from "./pages/Authentication/OTP";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgorPassword from "./pages/Authentication/ForgotPassword";
import ManageTrips from './pages/manageTrips/ManageTrips'
import Dashboard from './pages/dashboard/Dashboard'
import ManageBusinessAccounts from './pages/manageBusinessAccounts/ManageBusinessAccounts'
import BusinessAccountPriceList from './pages/manageBusinessAccounts/subPages/BusinessAccountPriceList'
import BusinessDashboard from './pages/manageBusinessAccounts/subPages/BusinessDashboard'
import Zone from './pages/manageZones/ManageZones'
// import store from './store';
import { Auth } from './auth';
// import { Provider } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';
import AdminLayout from "./layout/adminLayout/AdminLayout";
import { ConfigProvider } from 'antd';
import  { Toaster ,toast } from 'react-hot-toast';
import { useToasterStore } from 'react-hot-toast';



function App() {
  const helmetContext = {};
  const { toasts } = useToasterStore();
  if(toasts.length > 2){
    toast.remove(toasts[0].id);
  }
  return (
    <>
    <Toaster limit={1}/>
     <ConfigProvider
    theme={{
      components: {
        Tabs: {
          itemActiveColor: '#7e22cd',
        }},
        Switch: {
          colorPrimary: '#7e22cd'/* here is your component tokens */
        },
      // token: {
      //   colorPrimary: '24508d',
      // },
    }}
  >

 
    <link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Auth>

          <Routes>
            <Route element={<AdminLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/admin-users" element={<AdminUsers />} />
              <Route path="/manage-drivers" element={<ManageDrivers />} />
              <Route path="/manage-vehicles" element={<ManageVehicles />} />
              <Route path="/manage-trips" element={<ManageTrips />} />
              <Route path="/manage-business-accounts/:accountId" element={<BusinessAccountPriceList />} />
              <Route path="/manage-business-accounts/dashboard/:accountId" element={<BusinessDashboard />} />
              <Route path="/manage-business-accounts" element={<ManageBusinessAccounts />} />
              <Route path="/manage-zones" element={<Zone />} />
              <Route path="/dashboard" element={<Dashboard />} />

            </Route>
  
  
            <Route path="/signIn" element={<SignIn />} />
            <Route path="/forgotpassword" element={<ForgorPassword />} />
            <Route path="/verify-otp" element={<OTP />} />
            <Route path="/reset-password" element={<ResetPassword />} />
  
            {/* <Route key="def" path="*" element={<Navigate to="/" />} /> */}
          </Routes>
          </Auth>
          {/* <Footer /> */}
        </BrowserRouter>
      </HelmetProvider>
    </ConfigProvider>
  </>
  );
}

export default App;
