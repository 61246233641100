import jwtService from '../services/jwtService';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { setUserData, logoutUser } from './store/userSlice';
import Loader from '../components/loader';
import { useNavigate ,useLocation } from 'react-router-dom';
import {isRouteAllowed} from '../utils/functions'
import {routeMenuMapper} from '../utils/menuMap'

function Auth({ children }) {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    jwtCheck()
  }, [])

  const jwtCheck = async () => {
    await jwtService.init();
    await jwtService.signInWithToken().then(user => {
     if(user.error){
      navigate('/signIn')
      setWaitAuthCheck(false)
      return;
     }
     if(!isRouteAllowed(user.admin.role , routeMenuMapper , location.pathname)) navigate('/')
      dispatch(setUserData(user.admin));
      setWaitAuthCheck(false)
    }).catch(error => {
      setWaitAuthCheck(false)
    });
  };
  return waitAuthCheck ? <Loader /> : <>{children}</>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
