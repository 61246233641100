import { CustomTable } from "../../../components/CustomTable/CustomTable";

import { isEmpty } from "../../../utils/functions";
import { RenderColumnComponent } from "../../../components/CustomTable/RenderColumnComponent/RenderColumnComponent";
import {  MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import StatusComponent from "../../../components/StatusComponent/StatusComponent";


const TripsManagementTable = ({
  data,
  handleEditVehicle,
  defaultSort,
  activeRowId,
  handleToggleVehicleStatus,
  IS_SUPERVISOR,
  query,
  setQuery,
  setIsDrawerOpen,
  setTrip
}) => {
  const dispatch = useDispatch();

  const columnSortProps = (fieldName) => {
    if (isEmpty(fieldName)) return {};
    return {
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      ...(defaultSort?.orderColumn === fieldName && {
        defaultSortOrder: defaultSort?.orderBy,
      }),
    };
  };


  const columns = [
    {
      title: 'Business Account', 
      dataIndex: 'businessAccount.name', 
      key: 'businessAccount',
      sorter: false,
      width: 180,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(trip.businessAccount.name)}</div> ,
    },
    {
      title: 'Requested Vehicle', 
      dataIndex: 'vehicle', 
      key: 'vehicle',
      sorter: false,
      width: 180,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.vehicle?.vehicleType?.make?.en || 'N/A'}-${trip?.vehicle?.vehicleType?.model?.en || 'N/a'}` )}</div> ,
    },
    {
      title: 'Pick Up Time', 
      dataIndex: 'startAt', 
      key: 'startAt',
      width: 180,
      render: (text, trip) => RenderColumnComponent(true)(moment(trip.startAt).format('YYYY-MM-DD HH:mm')) ,
    },
    {
      title: 'Pick Up', 
      dataIndex: 'pickUp', 
      key: 'pickUp',
      sorter: false,
      width: 200,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.zoneFrom?.coordinates?.name?.en || 'N/a'}` )}</div> ,
    },
    {
      title: 'Drop Off', 
      dataIndex: 'dropOff', 
      key: 'dropOff',
      sorter: false,
      width: 150,
      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.zoneTo?.coordinates?.name?.en|| 'N/a'}` )}</div> ,
    },
    {
      title: 'Assigned Vehicle', 
      dataIndex: 'plateNumber', 
      key: 'plateNumber',
      sorter: false,
      width: 180,

      render: (text, trip) => <div className="flex gap-x-2">{RenderColumnComponent(true)(`${trip?.vehicle?.licensePlateNumber || 'N/a'}` )}</div> ,
    },
    {
      title: 'Assigned Driver', 
      dataIndex: 'driver', 
      width: 150,

      key: 'driver',
      sorter: false,
      render: (text, trip) => RenderColumnComponent(true)(trip.driver ? `${trip?.driver?.firstName || 'N/A'} ${trip?.driver?.lastName || 'N/A'}` : '-----') ,
    },
    {
      title: 'Status', 
      dataIndex: 'status', 
      width: 200,

      key: 'status',
      sorter: false,
      render: (text, trip) => RenderColumnComponent(true)(<StatusComponent status={trip.status}/>) ,
    },
    {
      title: 'Price', 
      dataIndex: 'status', 
      width: 100,

      key: 'status',
      sorter: false,
      render: (text, trip) => RenderColumnComponent(true)(trip.price || '----') ,
    },
    {
      title: "View/Edit", //"Active/Inactive",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (_, record) => {
    
        return (
          <>
            <MoreOutlined
            onClick={() => {
              dispatch(setTrip(record))
              setIsDrawerOpen(true)
            }}
             style={{ fontSize: "18px", marginRight: "8px" }}
              />
          </>
        );
      },
    },
  ];
 
  return (
      <CustomTable
        data={data?.content}
        elementsName='Trips'
        query={query}
        setQuery={setQuery}
        columns={
          columns?.map((column) => ({
            ...column,
            ...((column ).dataIndex !== "status" &&
            (column ).sorter !== false && {
              ...columnSortProps((column ).dataIndex),
            }),
          })) 
        }
        scrollPosition={IS_SUPERVISOR ? 27 : 21}
        scroll={true}
        pagination={true}
        totalRecords={data?.totalRecords} 
        activeRowId={activeRowId}
        />
  );
};

export default TripsManagementTable;
