import { configureStore } from '@reduxjs/toolkit';
import adminUser from '../pages/manageAdminUsers/store/index';
import auth from '../auth/store';
import driver from '../pages/manageDrivers/store/index';
import vehicle from '../pages/manageVehicles/store/index';
import trip from '../pages/manageTrips/store/index';
import businessAccount from '../pages/manageBusinessAccounts/store/index';
import zone from '../pages/manageZones/store/index';
import dashboard from '../pages/dashboard/store/index';
import user from '../auth/store/userSlice';

export const store = configureStore({
  reducer: {
    auth,
    adminUser,
    driver,
    vehicle,
    trip,
    businessAccount,
    zone,
    user,
    dashboard,
  }
});