import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtService from '../../services/jwtService';
import axios from 'axios';

const getDisplayName = user => {
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

const getPhotoUrl = user => `https://ui-avatars.com/api/?name=${getDisplayName(user)}`;

export const setUserData = user => async (dispatch, getState) => {
  const clearUserData = {
    role: user.role,
    data: {
      name: user.name,
      email: user.email,
      id: user._id,
      role: user.role,
      imageUrl:user.imageUrl,
    },
  };
  dispatch(setUser(clearUserData));
};

export const logoutUser = () => async (dispatch, getState) => {
  jwtService.logout();
  return dispatch(userLoggedOut());
};

export const updateUserData = createAsyncThunk(
  'auth/user/updateUserData',
  async ({ userID, reqParams }, { dispatch }) => {
    const response = await axios.patch(`user/${userID}`, reqParams);
    dispatch(setUserData(response.data));
    return response.data;
  }
);

const initialState = {
  data: {
    name: '',
    email: '',
    id: '',
    role: '',
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
